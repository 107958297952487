<template>
  <div class="container-birthdays-detail">
    <img src="https://images.ctfassets.net/3dar4x4x74wk/7qGaCELGpV91l7YdbriZBb/81dca0aabbac1d2dac61b89df70372be/MainEvent_PartyCitylogos.png" alt=""><br>
    <img src="https://images.ctfassets.net/3dar4x4x74wk/gFDuQOyuL7BDbrvyV65ER/f98d9b65fbfdd9a02ae3fd2e28ba67fb/ME_PartyCityPartytableware.png" alt="">
    <div class="container-packs-birthday">
      <div class="card-packs" v-for="c in objectCards" :key="c.id">
        <img class="img-circle" :src="c.img" alt="">
        <h4 v-html="c.title">  </h4>
        <img class="slick-arrow down-arrow" v-on:click="showDetail(c)" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTFweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMTEgMjIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDExIDIyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiM4QTBBRkYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iDQoJMSwxIDEwLDExIDEsMjEgIi8+DQo8L3N2Zz4NCg==" alt="Next" >   
        <p class="hovered-copy shown" v-html="c.text" :id="c.id+'_detail'">
        </p>
      </div> 
    </div>
  </div> 
</template>

<script>
export default {
  props: [
   
  ],
  methods:{
      showDetail(item){
        document.getElementById(item.id+"_detail").classList.toggle('shown');  
      }
  },
    data() {
    return {
      objectCards: [
          {id:"1",title:"Basic Balloon <br> Party Packs",img:"https://images.ctfassets.net/3dar4x4x74wk/3fEBVHx1vlOCYvFImDovOg/21adcf866e4f8304f486d4089230ec27/ME_Birthday_1.png", text:"<b>Includes</b> 2 Helium-Filled Balloon Bouquets (5ct), up to 2 Number Balloons and 3 Balloon Weights with team member setup prior to arrival.",state:false},
          {id:"2",title:"Boys Balloon  <br>Party Packs",img:"https://images.ctfassets.net/3dar4x4x74wk/2rXXDc54Z4Acw7QJpxYKj5/38887bff8ccab04ec578aff4fc6f992a/ME_Birthday_2.png", text:"<b>Includes</b> 2 Helium-Filled Balloon Bouquets (5ct), up to 2 Number Balloons and 3 Balloon Weights with team member setup prior to arrival.",state:false},
          {id:"3",title:"Girls Balloon <br>Party Packs",img:"https://images.ctfassets.net/3dar4x4x74wk/5CcJLiwU9WKqtg9h3UMqST/443d3e1d3577544df703a6495ee6922f/ME_Birthday_3.png", text:"<b>Includes</b> 2 Helium-Filled Balloon Bouquets (5ct), up to 2 Number Balloons and 3 Balloon Weights with team member setup prior to arrival.",state:false},
          {id:"4",title:"Delux Balloon <br>Party Packs",img:"https://images.ctfassets.net/3dar4x4x74wk/6tBa6ahwKU51yNO5NPt8X6/af8d1ee4804db869670c00c8d2f3751c/ME_Birthday_4.png", text:"<b>Includes</b> 2 Helium-Filled Balloon Bouquets (5ct), up to 2 Number Balloons and 3 Balloon Weights with team member setup prior to arrival.",state:false},
          {id:"5",title:"Tableware <br>Kits",img:"https://images.ctfassets.net/3dar4x4x74wk/4tdhkW9kXZxnk3Ni4nwFvN/869073aa66f8ebd5728305c1b67cd578/ME_Birthday_5.png", text:"<b>Includes</b> 2 Helium-Filled Balloon Bouquets (5ct), up to 2 Number Balloons and 3 Balloon Weights with team member setup prior to arrival.",state:false},
          {id:"6",title:"Solid <br>Tableware",img:"https://images.ctfassets.net/3dar4x4x74wk/2k752MKgf650gEWVitmrX6/0e673710ec1d57b463f5e004b5ec0347/ME_Birthday_6.png", text:"<b>Includes</b> 2 Helium-Filled Balloon Bouquets (5ct), up to 2 Number Balloons and 3 Balloon Weights with team member setup prior to arrival.",state:false},
          {id:"7",title:"Tableware <br>Add-ons",img:"https://images.ctfassets.net/3dar4x4x74wk/5tqrUQFVSukaoaPVtmTpyB/3d609b7c5f6c72a732a54b2a75fa9db9/ME_Birthday_7.png", text:"<b>Includes</b> 2 Helium-Filled Balloon Bouquets (5ct), up to 2 Number Balloons and 3 Balloon Weights with team member setup prior to arrival.",state:false},


        ]
    }
  },
}
</script>


<style lang="scss">
@import '../../assets/scss/_variables';

.container-packs-birthday {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
  }

  @media (min-width: 400px) {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5%;
  }
  
  @media (min-width: $md) {
    gap: 3.33333%;
  }
  
  @media (min-width: $lg) {
    gap: 2.5%;
  }
  
  .card-packs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 185px;
    margin: 10px 0;
    position: relative;

    @media (min-width: 400px) {
      width: 47.5%
    }

    @media (min-width: $sm) {
      width: 30%;
    }

    @media (min-width: $md) {
      width: 22.5%;
    }

    @media (min-width: $lg) {
      width: 18%;
    }

    p {
      display: none;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
    
    h4 {
      font-weight: 700;
      text-align: center !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    .arrow-down {
      transform: rotate(90deg);
      cursor: pointer;
      width: 11px;
    }

    .shown {
      display: none;
    }

    .hovered-copy {
      z-index: 10;
      width: 100%;
      border: 5px solid #8A0FFE;
      background: white;
      padding: 10px;
      position: absolute;
      left: 50%;
      right: -50%;
      transform: translate(-50%, 0);
      top: 100%;
    }
  }

  p {
    display: none;
  }
}
</style>