<template>
  <nuxt-link v-if="content.fields.internalLink" :to="content.fields.url" v-html="content.fields.title" :class="content.cssClass"/>
  <a v-else :href="content.fields.url" :class="content.cssClass" v-html="content.fields.title" />
</template>

<script>
export default {
  props: [
    'content',
  ]
}
</script>