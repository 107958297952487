<template>
  <picture>
    <source
      v-for="s in sizes"
      :key="s.index"
      :media="`(max-width:${s.break}px)`"
      :srcSet="s.url" />
    <img
      data-object-fit="cover"
      :data-src="defaultImg"
      :src="defaultImg"
      :alt="description"
      :class="cssClass || 'block w-full'" />
  </picture>
</template>

<script>
export default {
  props: [
    'sizes',
    'defaultImg',
    'description',
    'cssClass'
  ]
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/_variables';

.careers-hero {
  picture {
    img {
      height: 240px;

      @media (min-width: $sm) {
        height: 400px;
      }

      @media (min-width: $md) {
        height: 575px;
      }

      @media (min-width: $xl) {
        height: 650px;
      }
    }
  }

  .lockup {
    picture {
      img {
        width: 100%;
        margin-top: 40px;
        height: auto;
      }
    }
  }
}

.season-pass-hero {
  picture {
    img {
      max-height: 550px;
      object-position: center;
      @media (min-width: 640px) {
        max-height: 650px;
      }
    }
  }
}
</style>