<template>
  <div class="page-header" :class="activeRoute === 'birthdays'?'birthday-header':''">
    <img v-if="content.image && !content.video" :src="content.image.fields.file.url+'?w=808'" data-object-fit="cover" :data-src="content.image.fields.file.url+'?w=808'" :alt="content.image.fields.description" class="opacity-0" v-animate-onscroll="'animated fadeIn'"/>
    <div class="video-wrapper opacity-0" v-if="content.video" v-animate-onscroll="'animated fadeIn'">
      <video playsinline autoplay muted loop>
          <source :src="content.video.fields.file.url" type="video/mp4"/>
      </video>
    </div>
    <div>
      <ol v-if="!isLeagues" class="opacity-0" v-animate-onscroll="'animated fadeIn'">
        <li v-for="(link, index) in content.links" :key="link.index">
          <app-link :content="link"/><span v-if="content.links.length !== index+1">/</span>
        </li>
      </ol>
      <h1 class="opacity-0" v-animate-onscroll="'animated fadeIn delay-100'">{{content.heading}}</h1>
      <div v-if="activeRoute === 'birthdays'"  class="title-img" alt=""></div>
      
      <h2 :class="{ 'has-text': content.text }" class="opacity-0" v-animate-onscroll="'animated fadeIn delay-300'" v-if="content.subheading">{{content.subheading}}</h2>
      <p v-if="isQuestCube" class="opacity-0"  v-animate-onscroll="'animated fadeIn delay-500'">
         Ever wanted to step inside of a movie? Well now you can! Play exclusive multi-player games featuring characters from your favorite family movies, projected on all 4 walls of a special room. You play by throwing colored balls at moving targets on the walls around you. There is no special equipment needed and guests of all ages can play. It&apos;s a unique experience the whole family can enjoy! Available at Katy, Humble, Webster &amp; Stafford.
      </p>
      <p v-else-if="content.text" v-html="content.text" class="opacity-0"  v-animate-onscroll="'animated fadeIn delay-500'"/>
      <!-- <p v-else-if="isQuestCube" class="opacity-0"  v-animate-onscroll="'animated fadeIn delay-500'">
         Ever wanted to step inside of a movie? Well now you can! Play exclusive multi-player games featuring characters from your favorite family movies, projected on all 4 walls of a special room. You play by throwing colored balls at moving targets on the walls around you. There is no special equipment needed and guests of all ages can play. It&apos;s a unique experience the whole family can enjoy! Available at Katy, Humble, Webster &amp; Stafford.
      </p> -->
      <div v-if="content.detailsSection" class="pt-4 max-w-full">
        <details-section :content="content.detailsSection.fields" />
      </div>
      <div v-if="isLeagues" class="opacity-0" v-animate-onscroll="'animated fadeIn'">
        <div v-for="(link, index) in content.links" :key="link.index">
          <app-link class="button purple pulse mt-4" :content="link"/><span v-if="content.links.length !== index+1">/</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLink from '~/components/common/AppLink'
import DetailsSection from './DetailsSection'

export default {
  components: {
    AppLink,
    DetailsSection
  },
  props: [
    'content'
  ],
    data() {
    return {
      activeRoute:this.$route.name,
    }
  },
  computed: {
    isQuestCube() {
      if (this.$route.fullPath === '/experiences/quest-cube/') {
        return true
      } else {
        return false
      }
    },
    isLeagues() {
      if (this.$route.path.match('/the-leagues')) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    console.log('test: ', this.isQuestCube, this.$route)
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';

.page-header .details-section {
  & > div {
    width: 100% !important;
  }
  .details-section-details {
    width: auto !important;

    @media (max-width: 768px) {
      & > div {
        width: 100% !important;
      }
      .button.purple {
        width: 100% !important;
      }
    }
  }
  .details-section-details, .details-section-legal {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
div.birthday-header {
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    div{
      justify-content: center; 
      align-items: center;
      gap: 1rem;
      ol{
        font-size: 18px;
        display: none;
        @media (min-width: $md) {
          display: block;
        }
      }
    }
    img{
      max-width: 100%;
      width: 100%;
      @media (min-width: $md) {
        display: block;     
        max-width: 60%;
        width: 60%;
      }
    }
    h1{
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 27px;
      text-align: center;
      @media (min-width: $md) {
        font-size: 40px;
      }
    }
    h1::after {
      background-image: url(https://images.ctfassets.net/3dar4x4x74wk/5b624vq6OcdycOSwoAsftt/53d4f243dddfb0722d58a211b89a105c/ME_Awesome4Kids.png);
      background-size: 100% auto;
      display: inline-block;
      width: 17rem;
      height: 5rem;
      margin-bottom: 0px;
      margin-top: 0.5rem;
      background-repeat: no-repeat;
      content: '';
      @media (min-width: $md) {
        content: url(https://images.ctfassets.net/3dar4x4x74wk/5b624vq6OcdycOSwoAsftt/53d4f243dddfb0722d58a211b89a105c/ME_Awesome4Kids.png);
        background: none;
        height: auto;
        width: auto;
        margin-bottom: 2rem;
      }
    }
}
.party-city-packages{
  p {
    text-align: center !important;
    width: 360px;
    font-size: 17px;
      @media (min-width: $md) {
      width: 495px;
    }
  }
}
.pi-day-challenge {
  .page-header {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center; 
    img {
      min-height: auto;
      width: 100%;
      max-width: 100%;
      // transform: translateY(-140px);

      @media (min-width: $md) {
        margin-top: 25px;
        max-width: 75%;
        // transform: translateY(-80px);
      }
    }

    div {
      overflow: visible;
      h1 {
        font-size: 32px;
        text-align: center;
        margin: auto;
        color: white;

        @media (min-width: $md) {
          font-size: 28px;
        }

        &::after {
          content: '';
          display: inline-block;
          height: 5px;
          width: 70%;
          background: white;
        }
      }
    }
  }
}
</style>